import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import AddGallery from "../about/gallery/addphoto";
import AddLeadership from "../leadership/addleadership";
import SenateList from "../leadership/senatelist";
import DashBoard from "../dashboard/dashboard";
import Footer from "../footer/footer";
import AddNews from "../news/addnews";
import NewsList from "../news/newslist";
import NotFound from "../notfound/notfound";
import AddSlider from "../general/slider/addslider";
import SlidersList from "../general/slider/sliderslist";
import UpdateNews from "../news/updatenews";
import AddEvent from "../event/addevent";
import EventList from "../event/eventlist";
import UpdateEvent from "../event/updateevent";
import AddHeadline from "../general/headline/addheadline";
import HeadlinesList from "../general/headline/headlinelist";
import UpdateHeadline from "../general/headline/updateheadline";
import UpdateSlider from "../general/slider/updateslider";
import AlumniFeedbackList from "../alumni/viewalumni";
import AddAlumniFeedback from "../alumni/addfeedback";
import UpdateAlumniFeedback from "../alumni/updatefeedback";
import LeadershipList from "../leadership/leadershiplist";
import UpdateLeadership from "../leadership/updateleadership";
import ManagementList from "../leadership/managementlist";
import EnrolSenate from "../leadership/enrolsenate";
import EnrolManagement from "../leadership/enrolmanagement";
import PhotoList from "../about/gallery/photolist";
import UpdatePhoto from "../about/gallery/updatephoto";
import AddCategory from "../about/gallery/category/addcategory";
import AddSubCategory from "../about/gallery/subcategory/addsubcategory";
import BoardList from "../leadership/boardlist";
import AlumniList from "../alumni/alumnilist";
import CourseList from "../faculty/course/course";
import AddAboutDepartment from "../faculty/course/addaboutcourse";
import FacultyContent from "../faculty/content/content";
import LoadData from "../faculty/dashboard/loadData";
import PermissionPage from "../permission/permission";
import BazeInMedia from "../news/bazeinmedia";
import AddPermission from "../permission/addpermission";
import GroupMembers from "../permission/groupmembers";
import Login from "../login/login";
import Logout from "../login/logout";
import LoadCourseData from "../faculty/course/loadcoursedata";
import HomeContent from "../general/homecontent/homecontent";
import AddPartners from "../general/partners/addpartners";
import PartnersList from "../general/partners/partnerslist";
import UpdatePartners from "../general/partners/updatepartners";
import FacultyDashboard from "../faculty/dashboard/dashboard";

class PageRoutes extends Component {
    render() {
        return (
            <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">

                <Routes>
                    {/*Adam Musa Yau working enviroment*/}

                    {/* Dashboard Redirect */}
                    <Route exact path="/" element={<DashBoard/>} />

                    {/* About Route */}
                    <Route exact path="/about/gallery/addphoto" element={<AddGallery />} />
                    <Route exact path="/about/gallery/update" element={<UpdatePhoto />} />
                    <Route exact path="/about/gallery/list" element={<PhotoList/>} />
                    <Route exact path="/about/gallery/addcategory" element={<AddCategory/>} />
                    <Route exact path="/about/gallery/addsubcategory" element={<AddSubCategory/>} />
                    <Route exact path="/leadership/addleadership" element={<AddLeadership />} />
                    <Route exact path="/leadership/leadershiplist" element={<LeadershipList/>} />
                    <Route exact path="/leadership/boardlist" element={<BoardList/>} />
                    <Route exact path="/leadership/update" element={<UpdateLeadership />} />
                    <Route exact path="/leadership/enrolsenate" element={<EnrolSenate />} />
                    <Route exact path="/leadership/enrolmanagement" element={<EnrolManagement />} />
                    <Route exact path="/leadership/senatelist" element={<SenateList />} />
                    <Route exact path="/leadership/managementlist" element={<ManagementList />} />

                    {/* Event Route */}
                    <Route exact path="/news/general/add" element={<AddNews isFaculty={false} />} />
                    <Route exact path="/news/general/news/list" element={<NewsList isFaculty={false} />} />
                    <Route exact path="/news/general/update" element={<UpdateNews isFaculty={false} />} />
                    <Route exact path="/news/general/bazemedia" element={<BazeInMedia />} />

                    {/* News Route */}
                    <Route exact path="/event/general/add" element={<AddEvent />} />
                    <Route exact path="/event/general/list" element={<EventList />} />
                    <Route exact path="/event/general/update" element={<UpdateEvent />} />


                    {/* Faculty Route */}

                    {/*Faculty News*/}
                    <Route exact path="/faculty/dashboard/:slug" element={<LoadData  isFaculty={true} />}  />
                    <Route exact path="/faculty/news/add" element={<AddNews isFaculty={true} />} />
                    <Route exact path="/faculty/news/list" element={<NewsList isFaculty={true} />} />
                    <Route exact path="/faculty/news/update" element={<UpdateNews isFaculty={true} />} />

                    {/*Faculty Event*/}
                    <Route exact path="/faculty/event/add" element={<AddEvent isFaculty={true} />} />
                    <Route exact path="/faculty/event/list" element={<EventList isFaculty={true} />} />
                    <Route exact path="/faculty/event/update" element={<UpdateEvent isFaculty={true} />} />

                    {/*Faculty Content*/}
                    <Route exact path="/faculty" element={<FacultyDashboard/>} />
                    <Route exact path="/faculty/content" element={<FacultyContent isFaculty={true} />} />

                    <Route exact path="/faculty/course" element={<CourseList />} />
                    <Route exact path="/faculty/course/about" element={<LoadCourseData />} />

                    {/* Sani Abdallah working enviroment*/}
                     {/* Alumni Redirect */}
                    <Route exact path="/alumni/viewalumni" element={<AlumniFeedbackList />} />
                    <Route exact path="/alumni/feedback/list" element={<AlumniFeedbackList />} />
                    <Route exact path="/alumni/feedback/add" element={<AddAlumniFeedback />} />
                    <Route exact path="/alumni/feedback/update" element={<UpdateAlumniFeedback />} />
                    <Route exact path="/alumni/list" element={<AlumniList />} />


                    {/*Headline Redirect*/}
                    <Route exact path="/general/headline/addheadline" element={<AddHeadline/>} />
                    <Route exact path="/general/headline/headlinelist" element={<HeadlinesList/>} />
                    <Route exact path="/general/headline/update" element={<UpdateHeadline/>} />

                    {/*Slider Redirect*/}
                    <Route exact path="/general/slider/addslider" element={<AddSlider/>} />
                    <Route exact path="/general/slider/sliderslist" element={<SlidersList/>} />
                    <Route exact path="/general/slider/update" element={<UpdateSlider/>} />

                    {/*Partners Redirect*/}
                    <Route exact path="/general/partners/add" element={<AddPartners/>} />
                    <Route exact path="/general/partners/list" element={<PartnersList/>} />
                    <Route exact path="/general/partners/update" element={<UpdatePartners/>} />

                    <Route exact path="/content" element={<HomeContent/>} />

                    <Route exact path="/permission" element={<PermissionPage/>} />
                    <Route exact path="/permission/add" element={<AddPermission/>} />
                    <Route exact path="/permission/group/members" element={<GroupMembers/>} />
                    <Route exact path="/login" element={<Login/>} />
                    <Route exact path="/logout" element={<Logout/>} />

                    <Route path="*" element={<NotFound/>}/>

                </Routes>
                <Footer />
               </div>
               </div>
               </div>
        )

    }
}

export default PageRoutes;