import React, { Component } from "react";
import { Link } from "react-router-dom";
import {serverLink, shortCode} from "../../../resources/url";
import { setgeneralDetails } from "../../../actions/setgeneraldetailsaction";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import axios from "axios";
import 'react-data-table-component-extensions/dist/index.css';
import Header from "../../Header/header";
import bbanner from "../../../images/compter.jpg";
import { setCourseDetails } from "../../../actions/facultyactions";
import { accessControl } from "../../../permission/permission";

class CourseList extends Component {
    constructor(props) {
        super(props);
        accessControl([1, 2, 3, 4]);
        this.state = {
            isLoading: true,
            facultyCode: this.props.facultyData.FacultyCode,
            facultyID: this.props.facultyData.EntryID,
            Slug: this.props.facultyData.Slug,
            FacultyImage: this.props.facultyDetails.length === 0 ? "" : this.props.facultyDetails[0].FacultyImage,
            columns: [

                {
                    name: "Course Name ",
                    selector: row => `${row.CourseName}`,
                    sortable: true
                },
                {
                    name: "Course Code",
                    selector: row => `${row.CourseCode}`,
                    sortable: true,
                },
                {
                    name: "Duration",
                    selector: row => `${row.Duration}` + " " + `${row.DurationType}`,
                    sortable: true
                },
                {
                    name: "Action",
                    cell: (row) => (
                        <Link to="/faculty/course/about">
                            <button
                                type="button"
                                onClick={async () => {
                                    const courseData = {
                                        ...row
                                    };
                                    this.props.setOnCourseDetails(courseData);
                                }}
                                className="btn btn-primary btn-sm"
                            >
                                <i className="fa fa-plus-square" aria-hidden="true" />
                                Add About Course
                            </button>
                        </Link>
                    ),
                },


            ],
            data: []
        }
    }

    componentDidMount() {
        this.fetchCourse();
     //   this.fetchAboutDept();
    }

    async fetchAboutDept() {
        await axios.get(`${serverLink}course/list/all/${this.state.slug}`)
            .then(data => {
                const result = data.data;
                if (result.length !== 0) {
                    this.props.setOnGeneralDetails(result)
                    result.map((val) => {
                        this.setState({
                            [this.state.formData.id]: val.EntryID,
                            [this.state.formData.content]: val.Description,
                            [this.state.formData.photo]: val.ImagePath,
                            [this.state.formData.CourseHOD]: val.CourseHOD,
                            [this.state.formData.HODContent]: val.HODContent,
                        });
                    })
                } else {
                    this.props.setOnGeneralDetails([])
                }

            });
        this.setState({ isLoading: false })

    }

    async fetchCourse() {
        await axios.get(`${serverLink}academics/course/list/${this.state.facultyCode}`)
            .then(data => {
                const result = data.data;
                this.setState({
                    data: [...result]

                });
            });
    }

    render() {
        const columns = this.state.columns;
        const data = this.state.data;
        const tableData = {
            columns,
            data,
            print: false,
            exportHeaders: true,
            "export": true,
            filterPlaceholder: 'Search Course',
        };
        return (
            <>
                <Header isFaculty={true} />
                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                    {/* <!--begin::Toolbar--> */}
                    <div className="toolbar" id="kt_toolbar">
                        {/* <!--begin::Container--> */}
                        <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                            {/* <!--begin::Page title--> */}
                            <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
                                {/* <!--begin::Title--> */}
                                <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">Dashboard
                                    {/* <!--begin::Separator--> */}
                                    <span className="h-20px border-gray-200 border-start ms-3 mx-2" />
                                    {/* <!--end::Separator--> */}
                                </h1>
                                {/* <!--end::Title--> */}
                            </div>
                            {/* <!--end::Page title--> */}
                            {/* <!--begin::Actions--> */}
                            {/* <!--end::Actions--> */}
                        </div>
                        {/* <!--end::Container--> */}
                    </div>
                    {/* <!--end::Toolbar--> */}
                    {/* <!--begin::Post--> */}
                    <div className="post d-flex flex-column-fluid" id="kt_post">
                        {/* <!--begin::Container--> */}
                        <div id="kt_content_container" className="container-fluid">
                            {/* <!--begin::Row--> */}
                            <div className="card mb-5 mb-xl-10">
                                <div className="card-body py-10">
                                    <h2 className="mb-5 fw-bolder text-uppercase bi-pin-angle-fill " style={{ textAlign: 'left' }}> {this.props.facultyData.FacultyName}</h2>
                                    <div className="" style={{ height: '400px' }}>
                                        {
                                            this.state.FacultyImage !== "" ?
                                                <img src={`${serverLink}public/uploads/${shortCode}/faculty_uploads/${this.state.FacultyImage}`} className="img-thumbnail" style={{ backgroundColor: '#eeeeee', height: '400px', width: '100%' }} alt="Banner" />
                                                :
                                                <img alt="Logo" src={bbanner} className="img-thumbnail" style={{ backgroundColor: '#eeeeee', height: '400px', width: '100%' }} />

                                        }                                    </div>
                                    <DataTableExtensions
                                        {...tableData}
                                    >
                                        <DataTable
                                            noHeader
                                            defaultSortField="id"
                                            defaultSortAsc={false}
                                            pagination
                                            highlightOnHover />
                                    </DataTableExtensions>
                                </div>
                            </div>
                            {/* <!--end::Container--> */}
                        </div>
                        {/* <!--end::Post--> */}
                    </div>
                    {/* <!--end::Content--> */}
                </div>
            </>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        facultyData: state.facultyDetails,
        facultyDetails: state.facultyData,

    };
};

const mapDisptachToProps = (dispatch) => {
    return {
        setOnCourseDetails: (p) => {
            dispatch(setCourseDetails(p));
        },
        setOnGeneralDetails: (p) => {
            dispatch(setgeneralDetails(p));
        },
    };
};


export default connect(mapStateToProps, mapDisptachToProps)(CourseList);