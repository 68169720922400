import React, {Component} from "react";
import { connect } from "react-redux";
import {
    setgeneralDetails, setPermissionDetails
} from "../../actions/setgeneraldetailsaction";
import { Link } from "react-router-dom";
import { Oval } from  'react-loader-spinner'
import {serverLink} from "../../resources/url";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import axios from "axios";
import Header from "../Header/header";
class PermissionPage extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: true,
            columns: [
                {
                    name: "Group Name",
                    selector: row => `${row.GroupName}`,
                    sortable: true,
                },
                {
                    name: "Permission",
                    cell: (row) => (
                        <Link to="/permission/add">
                            <button
                                type="button"
                                onClick={async () => {
                                    const permissionData = {
                                        ...row
                                    };
                                    this.props.setOnPermissionDetails(permissionData);
                                }}
                                className="btn btn-primary btn-sm"
                            >
                                <i className="fa fa-plus-square" aria-hidden="true"/>

                            </button>
                        </Link>
                    ),
                },
                {
                    name: "Permission Report",
                    cell: (row) => (
                        <Link to="/permission/group/members">
                            <button
                                type="button"
                                onClick={async () => {
                                    const permissionData = {
                                        ...row
                                    };
                                    this.props.setOnPermissionDetails(permissionData);
                                }}
                                className="btn btn-primary btn-sm"
                            >
                                <i className="fa fa-eye" aria-hidden="true"/>

                            </button>
                        </Link>
                    ),
                }

            ],
            data: []
        }
    }

    componentDidMount() {
        this.fetchPermissionGroup();
    }

     fetchPermissionGroup() {
                const result = [
                    {
                        GroupName: 'Super Admin',
                        GroupID: '1',
                    },
                    {
                        GroupName: 'Faculty',
                        GroupID: '2',
                    },
                    {
                        GroupName: 'Department',
                        GroupID: '3',
                    },
                    {
                        GroupName: 'DSSD',
                        GroupID: '4',
                    }
                ];

                this.setState({
                    data: [...result]

                });
        this.setState({isLoading: false})
    }


    render() {
        const columns = this.state.columns;
        const data = this.state.data;
        const tableData = {
            columns,
            data,
            print: false,
            exportHeaders: true,
            filterPlaceholder: 'Search group',

        };
        return ( <>
                <Header isFaculty={false}/>
                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                    <div className="toolbar" id="kt_toolbar">
                        <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                            <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
                                <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">Permission
                                    <span className="h-20px border-dark  border-start ms-3 mx-2"/>
                                    Group
                                    <span className="h-20px border-dark  border-start ms-3 mx-2"/>
                                    List
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className="post d-flex flex-column-fluid" id="kt_post">
                        <div id="kt_content_container" className="container-fluid">
                            {/* CONTENT START */}
                            {this.state.isLoading ?
                                <div style={{margin: 'auto', position: 'relative', justifyContent:'center', alignItems: 'center', display: 'flex' , height: '400px'}}>
                                    <Oval
                                        type="Oval"
                                        color="#eeeeee"
                                        height={50}
                                        width={50}
                                        secondaryColor="teal"
                                        timeout={15000} //3 secs
                                    />
                                </div>
                                :
                                <div className="card mb-5 mb-xl-10">
                                    <div className="card-header">
                                        <div className="card-title">
                                            <h3>GROUP LIST</h3>
                                        </div>


                                    </div>

                                    <div className="card-body"  style={{textAlign: 'left'}}>
                                        <DataTableExtensions
                                            {...tableData}
                                        >
                                            <DataTable
                                                noHeader
                                                defaultSortField="id"
                                                defaultSortAsc={false}
                                                pagination
                                                highlightOnHover/>
                                        </DataTableExtensions>

                                    </div>

                                </div>}

                            {/* CONTENT END */}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapDisptachToProps = (dispatch) => {
    return {
        setOnPermissionDetails: (p) => {
            dispatch(setPermissionDetails(p));
        },
    };
};

export default connect(null, mapDisptachToProps)(PermissionPage);