import React, {Component} from "react";
import {Link} from "react-router-dom";

class NotFound extends Component {
    render() {
        return (
            <div className="container-fluid block full-bleed four-o-roar" style={{ marginTop: "160px" }}>
                <div className="row">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 animation-message">
                                <div className="message">

                                    <h2>Page not found!</h2>

                                    <div id="search-ucalgary">
                                        <form >
                                            <div className="input-group input-group-lg">
                                                <input className="form-control" type="text" placeholder="Search Course, Staff, Publication"/>
                                                <span className="input-group-btn">
                                                    <button className="btn btn-default btn-search" type="submit">
                                                        <span className="visible-md-block visible-lg-block search-text">
                                                          Search
                                                        </span>
                                                    </button>
                                                </span>
                                            </div>
                                        </form>
                                    </div>

                                    <p>
                                        <Link to="/">
                                            Go to dashboard
                                        </Link>
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default NotFound;