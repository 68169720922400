import React, { Component } from "react";
import axios from "axios";
import swal from 'sweetalert';
import { serverLink } from "../../../resources/url";
import Header from "../../Header/header";
import { accessControl } from "../../../permission/permission";
import HomeContentForm from "./homecontentform";
import { connect } from "react-redux";

class HomeContent extends Component {
    constructor(props) {
        super(props);
        accessControl([1, 2, 4]);
        this.state = {
            isLoading: "off",
            imagefile: "",
            htmlelement: "",
            campusImageFile: "",
            campusImageFile2: "",
            DiscoverPictureFile: "",
            campusHtmlElement: "",
            campusHtmlElement2: "",
            DiscoverPictureElement: "",
            formData: {
                id: this.props.homeContent.length === 0 ? "" : this.props.homeContent[0].EntryID,
                DiscoverContent: this.props.homeContent.length === 0 ? "" : this.props.homeContent[0].DiscoverContent,
                DiscoverPicture: this.props.homeContent.length === 0 ? "" : this.props.homeContent[0].DiscoverPicture,
                AboutContent: this.props.homeContent.length === 0 ? "" : this.props.homeContent[0].AboutContent,
                CampusImage: this.props.homeContent.length === 0 ? "" : this.props.homeContent[0].CampusImage,
                CampusImage2: this.props.homeContent.length === 0 ? "" : this.props.homeContent[0].CampusImage2,
                CampusContent: this.props.homeContent.length === 0 ? "" : this.props.homeContent[0].CampusContent,
                CampusContent2: this.props.homeContent.length === 0 ? "" : this.props.homeContent[0].CampusContent2,
                EducationContent: this.props.homeContent.length === 0 ? "" : this.props.homeContent[0].EducationContent,
                RegistryContent: this.props.homeContent.length === 0 ? "" : this.props.homeContent[0].RegistryContent,
            },
        }
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: "on",
        });
        this.onAddFacultyContent();
    };

    async onAddFacultyContent() {
        let sendData = {
            ...this.state.formData,
            campusImageFile: this.state.campusImageFile,
            campusImageFile2: this.state.campusImageFile2,
            DiscoverPictureFile: this.state.DiscoverPictureFile,
            InsertedBy: "Adam Musa Yau"
        };


        if (this.state.formData.title === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please enter news title", "error")
            return false;
        }

        if (this.state.formData.content === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please enter news content", "error")
            return false;
        }

        axios.post(`${serverLink}general/content/add`, sendData)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if (response === "news exist") {
                    this.showAlert("Oops!", "News with the same name already exist", "error")
                    return false;
                } else if (response === "failed") {
                    this.showAlert("Oops!", "Something went wrong adding the news. Please try again!", "error")
                    return false;
                } else if (response === "success") {
                    //
                    if (this.state.DiscoverPictureFile !== "") {
                        const formData = new FormData();
                        formData.append('photo', this.state.DiscoverPictureFile);
                        formData.append('entry_id', this.state.formData.id === "" ? result.data.entry_id : this.state.formData.id)
                        axios.patch(`${serverLink}general/content/uploadPhoto`, formData)
                            .then(result => {
                                this.setState({
                                    isLoading: false,
                                });
                                const res = result.data.lastID;
                                // console.log(res);
                            })
                            .catch(err => {
                                this.setState({
                                    isLoading: false,
                                });
                                console.error('ERROR', err);
                            });
                    }
                    //
                    if (this.state.campusImageFile !== "") {
                        const formData = new FormData();
                        formData.append('campus_photo', this.state.campusImageFile);
                        formData.append('entry_id', this.state.formData.id === "" ? result.data.entry_id : this.state.formData.id)
                        axios.patch(`${serverLink}general/content/campusPhoto`, formData)
                            .then(result => {
                                this.setState({
                                    isLoading: false,
                                });
                                const res = result.data.lastID;
                                // console.log(res);
                            })
                            .catch(err => {
                                this.setState({
                                    isLoading: false,
                                });
                                console.error('ERROR', err);
                            });
                    }
                    //
                    if (this.state.campusImageFile2 !== "") {
                        const formData = new FormData();
                        formData.append('campus_photo2', this.state.campusImageFile2);
                        formData.append('entry_id', this.state.formData.id === "" ? result.data.entry_id : this.state.formData.id)
                        axios.patch(`${serverLink}general/content/campusPhoto2`, formData)
                            .then(result => {
                                this.setState({
                                    isLoading: false,
                                });
                                const res = result.data.lastID;
                                // console.log(res);
                            })
                            .catch(err => {
                                this.setState({
                                    isLoading: false,
                                });
                                console.error('ERROR', err);
                            });
                    }

                    this.showSuccessAlert("SUCCESS", "RECORD WAS ADDED SUCCESSFULLY", "success")
                    return false;
                } else {
                    this.showAlert("Oops!", "Failed to add the faculty content!", "error")
                    return false;
                }

            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                });
                console.error('ERROR', err);
            });
    }

    onEdit = (e) => {
        const formData = this.state.formData;
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    onImageChange = (event) => {

        if (event.target.id === 'DiscoverPicture') {
            const formData = this.state.formData;
            this.setState({
                DiscoverPictureFile: event.target.files[0],
                DiscoverPictureElement: URL.createObjectURL(event.target.files[0])
            });
        }

        if (event.target.id === 'CampusImage') {
            const formData = this.state.formData;
            this.setState({
                campusImageFile: event.target.files[0],
                campusHtmlElement: URL.createObjectURL(event.target.files[0])
            });
        }

        if (event.target.id === 'CampusImage2') {
            const formData = this.state.formData;
            this.setState(
                {
                    campusImageFile2: event.target.files[0],
                    campusHtmlElement2: URL.createObjectURL(event.target.files[0])
                });
        }

        // if (event.target.name === 'DiscoverPicture'){
        //     if (event.target.files && event.target.files[0]) {
        //         this.setState({
        //             imagefile: event.target.files[0],
        //         });
        //
        //         let reader = new FileReader();
        //         reader.onload = (e) => {
        //             this.setState({
        //                 htmlelement: (
        //                     <div className="addpersonpage" style={{height: '400px', }}>
        //                         <img
        //                             className="netimage img-thumbnail"
        //                             srcSet={e.target.result}
        //                             alt="profileImage" style={{height: '400px', width: '100%'}}
        //                         />
        //                     </div>
        //                 ),
        //             });
        //         };
        //         reader.readAsDataURL(event.target.files[0]);
        //         this.setState({});
        //     }
        // }
        //
        // if (event.target.name === 'CampusImage'){
        //     if (event.target.files && event.target.files[0]) {
        //         this.setState({
        //             campusImageFile: event.target.files[0],
        //         });
        //
        //         let reader = new FileReader();
        //         reader.onload = (e) => {
        //             this.setState({
        //                 campusHtmlElement: (
        //                     <div className="addpersonpage" style={{height: '400px', }}>
        //                         <img
        //                             className="netimage img-thumbnail"
        //                             srcSet={e.target.result}
        //                             alt="profileImage" style={{height: '400px', width: '100%'}}
        //                         />
        //                     </div>
        //                 ),
        //             });
        //         };
        //         reader.readAsDataURL(event.target.files[0]);
        //         this.setState({});
        //     }
        // }
        //
        // if (event.target.name === 'CampusImage2'){
        //     if (event.target.files && event.target.files[0]) {
        //         this.setState({
        //             campusImageFile2: event.target.files[0],
        //         });
        //
        //         let reader = new FileReader();
        //         reader.onload = (e) => {
        //             this.setState({
        //                 campusHtmlElement2: (
        //                     <div className="addpersonpage" style={{height: '400px', }}>
        //                         <img
        //                             className="netimage img-thumbnail"
        //                             srcSet={e.target.result}
        //                             alt="profileImage" style={{height: '400px', width: '100%'}}
        //                         />
        //                     </div>
        //                 ),
        //             });
        //         };
        //         reader.readAsDataURL(event.target.files[0]);
        //         this.setState({});
        //     }
        // }
        //

    };

    showAlert(title, msg, type) {
        return swal({
            title: title,
            text: msg,
            icon: type,
            button: "OK",
        });
    }

    showSuccessAlert(title, msg, type) {
        return swal({
            title: title,
            text: msg,
            icon: type,
            button: "OK",
        }).then((value) => {
            window.location.href = `/`;
        });
    }

    render() {
        return (
            <>
                <Header isFaculty={this.props.isFaculty ? true : false} />
                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                    <div className="toolbar" id="kt_toolbar">
                        <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                            <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
                                <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
                                    {this.props.isFaculty ? "Faculty" : "General"}
                                    <span className="h-20px border-dark  border-start ms-3 mx-2" />
                                    Content
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className="post d-flex flex-column-fluid" id="kt_post">
                        <div id="kt_content_container" className="container-fluid">
                            {/* CONTENT START */}
                            <div className="card mb-5 mb-xl-10">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h3>WEBSITE HOME CONTENT</h3>
                                    </div>
                                </div>
                                
                                    <HomeContentForm
                                        handleSubmit={this.handleSubmit}
                                        onEdit={this.onEdit}
                                        onImageChange={this.onImageChange}
                                        imageElement={this.state.htmlelement}
                                        campusHtmlElement={this.state.campusHtmlElement}
                                        campusHtmlElement2={this.state.campusHtmlElement2}
                                        DiscoverPictureElement={this.state.DiscoverPictureElement}
                                        isLoading={this.state.isLoading}
                                        data={this.state.formData}
                                    />
                              
                            </div>
                            {/* CONTENT END */}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        homeContent: state.homeContentDetails,
    };
};
export default connect(mapStateToProps, null)(HomeContent);
