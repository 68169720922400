import React, { Component } from "react";
import axios from "axios";
import { serverLink } from "../../../../resources/url";
import swal from "sweetalert";
import SubcategoryForm from "./subcategoryform";
import { accessControl } from "../../../../permission/permission";
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from "react-data-table-component";
import {Oval} from "react-loader-spinner";
class AddSubCategory extends Component
{
    constructor()
    {
        super();
        accessControl([1, 4]);
        this.state = {
            isLoading: "off",
            imagefile: "",
            htmlelement: <i className="fa fa-user fa-8x" aria-hidden="true" />,
            formData: {
                EntryID: "",
                CategoryID: "",
                categoryName: "",
                subcategoryName: ""

            },
            categoryList: [],
            columns: [
                {
                    name: "CategoryName",
                    selector: row => `${row.CategoryName}`,
                    sortable: true,
                    width: '40%'
                },
                {
                    name: "SubCategoryName",
                    selector: row => `${row.SubCategoryName}`,
                    sortable: true,
                    width: '40%'
                },
                {
                    name: "Edit",
                    cell: (row) => (
                        <button
                            type="button"
                            onClick={async () => {
                                const formData = {
                                    ...row
                                };
                                this.setState({formData: { EntryID: row.EntryID, CategoryID: row.CategoryID, categoryName: row.CategoryID, subcategoryName: row.SubCategoryName }})
                                window.scrollTo({
                                    top: 0,
                                    left: 0,
                                    behavior: "smooth",
                                });
                            }}
                            className="btn btn-primary btn-sm"
                        >
                            <i className="fa fa-edit" aria-hidden="true"/>
                        </button>
                    ),
                },
                {
                    name: "Delete",
                    cell: (row) => (
                        <button
                            type="button"
                            onClick={async () => this.showConfirmAlert("Confirm", "ARE YOU SURE YOU WANT TO DELETE THIS RECORD?", "warning", row)
                            }
                            className="btn btn-danger btn-sm"
                        >
                            <i className="fa fa-trash-alt" aria-hidden="true"/>
                        </button>
                    ),
                }

            ],
            data: [],
            _isLoading: true,
        }
    }

    componentDidMount()
    {
        this.fetchlist();
        this.fetchGalleryCategoryList()
    }


    async fetchlist()
    {
        await axios.get(`${serverLink}gallery/subcategory/list`)
            .then(data =>
            {
                const result = data.data;
                this.setState({
                    data: [...result]

                });
            });
        this.setState({ _isLoading: false })
    }

    showConfirmAlert(title, msg, type, data)
    {
        return swal({
            title: title,
            text: msg,
            type: type,
            confirmButtonColor: '#DD6B55',
            buttons: true,
            dangerMode: true,
        }).then((isConfirm) =>
        {
            if (isConfirm)
            {
                this.onDelete(data);
            } else
            {

            }
        });
    }

    async onDelete(data)
    {
        await axios.delete(`${serverLink}gallery/subcategory/delete/${data.EntryID}`).then(data =>
        {
            const result = data.data;
            if (result.message === "deleted")
            {
                this.fetchlist();
                this.showAlert("SUCCESS", "GALLERY SUB CATEGORY DELETED SUCCESSFULLY", "success")

                return false;
            }
        });
    }



    async fetchGalleryCategoryList()
    {
        await fetch(`${serverLink}gallery/category/list`)
            .then(res => res.json())
            .then(data =>
            {
                this.setState({
                    categoryList: data,
                    isLoading: false,
                });
            });
    }
    handleSubmit = (e) =>
    {
        e.preventDefault();
        this.setState({
            isLoading: "on",
        });
        this.onAddSubCategory();
    };

    async onAddSubCategory() {
        let sendData = {
            ...this.state.formData,
        };

        if (this.state.formData.categoryName === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please select category name", "error")
            return false;
        }
        if (this.state.formData.subcategoryName === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please enter sub category name", "error")
            return false;
        }


        if (this.state.formData.EntryID === "") {
            axios.post(`${serverLink}gallery/subcategory/add`, sendData)
                .then(result => {
                    this.setState({
                        isLoading: false,
                    });
                    const response = result.data.message;
                    if (response === "name exist") {
                        this.showAlert("Oops!", "sub-category with the same name already exist", "error")
                        return false;
                    } else if (response === "failed") {
                        this.showAlert("Oops!", "Something went wrong adding the sub-category. Please try again!", "error")
                        return false;
                    } else if (response === "success") {
                        const formData = this.state.formData;
                        this.setState({
                            formData: {
                                ...formData,
                                categoryName: '',
                                subcategoryName: ''
                            },
                        });
                        this.fetchlist();
                        this.showAlert("SUCCESS", "SUB CATEGORY ADDED SUCCESSFULLY", "success")
                        return false;
                    } else {
                        this.showAlert("Oops!", "Failed to add the sub-category details!", "error")
                        return false;
                    }

                })
                .catch(err => {
                    this.setState({
                        isLoading: false,
                    });
                    console.error('ERROR', err);
                });

        } else {
            axios.patch(`${serverLink}gallery/subcategory/update`, sendData)
                .then(result => {
                    this.setState({
                        isLoading: false,
                    });
                    const response = result.data.message;
                    if (response === "failed") {
                        this.showAlert("Oops!", "Something went wrong adding the category. Please try again!", "error")
                        return false;
                    } else if (response === "success") {
                        this.showAlert("SUCCESS", "SUB CATEGORY UPDATED SUCCESSFULLY", "success");
                        this.setState({formData: {EntryID: "", categoryName: "", subcategoryName: '', CategoryID: ''}})
                        this.fetchlist();
                        return false;
                    } else {
                        this.showAlert("Oops!", "Failed to add the category details!", "error")
                        return false;
                    }

                })
                .catch(err => {
                    this.setState({
                        isLoading: false,
                    });
                    console.error('ERROR', err);
                });
        }
    }

    onEdit = (e) =>
    {
        const formData = this.state.formData;
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    showAlert(title, msg, type)
    {
        return swal({
            title: title,
            text: msg,
            icon: type,
            button: "OK",
        });
    }
    render()
    {
        let count = 1;
        const columns = this.state.columns;
        const data = this.state.data;
        const tableData = {
            columns,
            data,
            print: false,
            exportHeaders: true,
            "export": true,
            filterPlaceholder: 'Search gallery sub category',
        };
        return (
            this.state._isLoading ?

                <div style={{ margin: 'auto', position: 'relative', justifyContent: 'center', alignItems: 'center', display: 'flex', height: '400px' }}>
                    <Oval
                        type="Oval"
                        color="#eeeeee"
                        height={50}
                        width={50}
                        secondaryColor="teal"
                        timeout={15000} //3 secs
                    />
                </div> :
                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                    <div className="toolbar" id="kt_toolbar">
                        <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                            <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
                                <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">About
                                    <span className="h-20px border-dark  border-start ms-3 mx-2" />
                                    GALLERY
                                    <span className="h-20px border-dark  border-start ms-3 mx-2" />
                                    ADD SUB-CATEGORY
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className="post d-flex flex-column-fluid" id="kt_post">
                        <div id="kt_content_container" className="container-fluid">
                            {/* CONTENT START */}
                            <div className="card mb-5 mb-xl-10">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h3>ADD SUB-CATEGORY</h3>
                                    </div>
                                </div>
                                <SubcategoryForm
                                    handleSubmit={this.handleSubmit}
                                    onEdit={this.onEdit}
                                    isLoading={this.state.isLoading}
                                    data={this.state.formData}
                                    categoryList={this.state.categoryList}
                                />
                                <div className="card-body" style={{ textAlign: 'left' }}>
                                    <DataTableExtensions
                                        {...tableData}
                                    >
                                        <DataTable
                                            noHeader
                                            defaultSortField="id"
                                            defaultSortAsc={false}
                                            pagination
                                            highlightOnHover />
                                    </DataTableExtensions>

                                </div>
                            </div>
                            {/* CONTENT END */}
                        </div>
                    </div>
                </div>
        )
    }
}

export default AddSubCategory;