import React, { Component } from "react";
import axios from "axios";
import {serverLink, shortCode} from "../../../resources/url";
import swal from "sweetalert";
import Header from "../../Header/header";
import bbanner from "../../../images/compter.jpg";
import { connect } from "react-redux";
import { setgeneralDetails } from "../../../actions/setgeneraldetailsaction";
import AboutCourseForm from "./aboutcourseform";
import {accessControl} from "../../../permission/permission";

class AddAboutCourse extends Component {
    constructor(props) {
        super(props);
        accessControl([1, 2, 3, 4]);
        this.state = {
            isLoading: true,
            imagefile: "",
            htmlelement: "",
            formData: {
                id: this.props.generalDetails.length === 0 ? "" : this.props.generalDetails[0].EntryID,
                courseCode: this.props.courseData.CourseCode,
                courseName: this.props.courseData.CourseName,
                slug: this.props.courseData.Slug.replace(/[/]/g, "-"),
                content: this.props.generalDetails.length === 0 ? "" : this.props.generalDetails[0].Description,
                photo: this.props.generalDetails.length === 0 ? "" : this.props.generalDetails[0].ImagePath,
                CourseHOD: this.props.generalDetails.length === 0  ? "" : this.props.generalDetails[0].CourseHOD,
                HODContent: this.props.generalDetails.length === 0 ? "" : this.props.generalDetails[0].HODContent,
                ImagePath: this.props.generalDetails.length === 0 ? "" : this.props.generalDetails[0].ImagePath,
            },
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: "on",
        });
        this.onAddAboutDepartment();
    };

    async onAddAboutDepartment() {
        let sendData = {
            ...this.state.formData,
            submittedBy: `${this.props.loginDetails[0].FirstName} ${this.props.loginDetails[0].MiddleName} ${this.props.loginDetails[0].Surname}`,
            updatedBy: `${this.props.loginDetails[0].FirstName} ${this.props.loginDetails[0].MiddleName} ${this.props.loginDetails[0].Surname}`,
        };

       await axios.post(`${serverLink}academics/course/details/add`, sendData)
            .then(async result =>  {
                const response = result.data.message;
                if (response === "failed") {
                    this.showAlert("Oops!", "Something went wrong adding the about department. Please try again!", "error")
                    return false;
                } else if (response === "success") {
                    if (this.state.imagefile !== "") {
                        const formData = new FormData();
                        formData.append('photo', this.state.imagefile);
                        formData.append('entry_id', this.state.formData.id === "" ? result.data.entry_id : this.state.formData.id)
                      await  axios.patch(`${serverLink}academics/course/uploadCoursePhoto`, formData)
                            .then(result => {
                                console.log(result.data)
                                this.setState({
                                    isLoading: "off",
                                });
                            })
                            .catch(err => {
                                this.setState({
                                    isLoading: "off",
                                });
                                console.error('ERROR', err);
                            });
                    }
                    this.setState({
                        isLoading: "off",
                    });
                    this.showAlert("SUCCESS", "ABOUT COURSE ADDED SUCCESSFULLY", "success")
                    return false;
                } else {
                    this.showAlert("Oops!", "Failed to add the about course details!", "error")
                    return false;
                }

            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                });
                console.error('ERROR', err);
            });
    }

    onEdit = (e) => {
        const formData = this.state.formData;
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    // onImageChange = (event) => {
    //     const formData = this.state.formData;
    //     this.setState({formData: {
    //             ...formData, photo: event
    //         },});
    // };

    onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0]
            if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") {
            } else {
                this.showAlert("Oops!", "Only .png, .jpg and .jpeg format allowed!", "error")
                return false;
            }
            if (file.size > 2000000) {
                this.showAlert("Oops!", "max file size is 2mb", "error")
                return false;
            }

            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
                this.setState({
                    imagefile: event.target.files[0],
                    htmlelement: e.target.result,
                    image_name: file.name,
                    view: file.mozFullPath,
                });
            };
        }
    };

    showAlert(title, msg, type) {
        return swal({
            title: title,
            text: msg,
            icon: type,
            button: "OK",
        }).then((value) => {
            window.location.href = '/faculty/course';
        });
    }
    render() {
        return (
            <>
                <Header isFaculty={true} />
                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                    {/* <!--begin::Toolbar--> */}
                    <div className="toolbar" id="kt_toolbar">
                        {/* <!--begin::Container--> */}
                        <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                            {/* <!--begin::Page title--> */}
                            <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
                                {/* <!--begin::Title--> */}
                                <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">Dashboard
                                    {/* <!--begin::Separator--> */}
                                    <span className="h-20px border-gray-200 border-start ms-3 mx-2" />
                                    {/* <!--end::Separator--> */}
                                </h1>
                                {/* <!--end::Title--> */}
                            </div>
                            {/* <!--end::Page title--> */}
                            {/* <!--begin::Actions--> */}
                            {/* <!--end::Actions--> */}
                        </div>
                        {/* <!--end::Container--> */}
                    </div>
                    {/* <!--end::Toolbar--> */}
                    {/* <!--begin::Post--> */}
                    <div className="post d-flex flex-column-fluid" id="kt_post">
                        {/* <!--begin::Container--> */}
                        <div id="kt_content_container" className="container-fluid">
                            {/* <!--begin::Row--> */}
                            <div className="card mb-5 mb-xl-10">
                                <div className="card-body py-10">
                                    <h2 className="mb-5 fw-bolder text-uppercase bi-pin-angle-fill " style={{ textAlign: 'left' }}> {this.state.formData.courseName}</h2>
                                    <div className="" style={{ height: '400px' }}>
                                        {
                                            this.state.htmlelement === "" ?

                                                this.state.formData.photo !== "" || this.state.formData.photo === null ?
                                                    <img src={this.state.formData.photo.includes("simplefileupload") ? this.state.formData.photo : `${serverLink}public/uploads/${shortCode}/course_uploads/${this.state.formData.photo}`} className="img-thumbnail" style={{ backgroundColor: '#eeeeee', height: '400px', width: '100%' }} alt="Banner" />
                                                    // <img src={`${serverLink}public/uploads/${shortCode}/course_uploads/${this.state.formData.photo}`} className="img-thumbnail" style={{ backgroundColor: '#eeeeee', height: '400px', width: '100%' }} alt="Banner" />
                                                    :
                                                    <img alt="Logo" src={bbanner} className="img-thumbnail" style={{ backgroundColor: '#eeeeee', height: '400px', width: '100%' }} />
                                                :
                                                <img src={this.state.htmlelement} className="img-thumbnail" style={{ backgroundColor: '#eeeeee', height: '400px', width: '100%' }} alt="Banner" />

                                        }                                 </div>

                                    <AboutCourseForm
                                        handleSubmit={this.handleSubmit}
                                        onEdit={this.onEdit}
                                        onImageChange={this.onImageChange}
                                        isLoading={this.state.isLoading}
                                        data={this.state.formData}
                                        image={this.state.htmlelement}
                                    />
                                </div>
                            </div>
                            {/* <!--end::Container--> */}
                        </div>
                        {/* <!--end::Post--> */}
                    </div>
                    {/* <!--end::Content--> */}
                </div>
            </>
        )
    }
}

const mapDisptachToProps = (dispatch) => {
    return {
        setOnGeneralDetails: (p) => {
            dispatch(setgeneralDetails(p));
        },
    };
};

const mapStateToProps = (state) => {
    return {
        courseData: state.courseDetails,
        generalDetails: state.generalDetails,
        loginDetails: state.loginDetails,
    };
};

export default connect(mapStateToProps, mapDisptachToProps)(AddAboutCourse);
