import {Component} from "react";

class Logout extends Component {
    constructor() {
        super();
        this.onLogoutCall();
    }


    onLogoutCall = () =>{
        window.sessionStorage.setItem("isLoggedIn", false);
        window.sessionStorage.clear();
        window.location.href = '/login';
    }

    componentDidMount() {
        this.onLogoutCall();
    }
}

export default Logout;