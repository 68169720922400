import React, { Component } from "react";
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateFromHTML } from "draft-js-import-html";
import bbanner from "../../../images/placeholder.png";
import {projectName, projectUploadAPI, serverLink, shortCode} from "../../../resources/url";
import SimpleFileUpload from "react-simple-file-upload";

class HomeContentForm extends Component {
    constructor(props) {
        super(props);
        const contentBlocks = stateFromHTML(this.props.data.DiscoverContent);
        const contentBlocks2 = stateFromHTML(this.props.data.AboutContent);
        const contentBlocks3 = stateFromHTML(this.props.data.CampusContent);
        const contentBlocks4 = stateFromHTML(this.props.data.EducationContent);
        const contentBlocks5 = stateFromHTML(this.props.data.RegistryContent);
        const contentBlocks6 = stateFromHTML(this.props.data.CampusContent2);
        this.state = {
            editorState: EditorState.createWithContent(contentBlocks !== "" ? contentBlocks : ''),
            editorState2: EditorState.createWithContent(contentBlocks2 !== "" ? contentBlocks2 : ''),
            editorState3: EditorState.createWithContent(contentBlocks3 !== "" ? contentBlocks3 : ''),
            editorState4: EditorState.createWithContent(contentBlocks4 !== "" ? contentBlocks4 : ''),
            editorState5: EditorState.createWithContent(contentBlocks5 !== "" ? contentBlocks5 : ''),
            editorState6: EditorState.createWithContent(contentBlocks6 !== "" ? contentBlocks6 : ''),
        };
    }

    onEditorStateChange = editorState => {
        this.setState({
            editorState,
        });
        this.props.data.DiscoverContent = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    };

    onEditorStateChange2 = editorState2 => {
        this.setState({
            editorState2,
        });
        this.props.data.AboutContent = draftToHtml(convertToRaw(editorState2.getCurrentContent()));
    };

    onEditorStateChange3 = editorState3 => {
        this.setState({
            editorState3,
        });
        this.props.data.CampusContent = draftToHtml(convertToRaw(editorState3.getCurrentContent()));
    };

    onEditorStateChange4 = editorState4 => {
        this.setState({
            editorState4,
        });
        this.props.data.EducationContent = draftToHtml(convertToRaw(editorState4.getCurrentContent()));
    };

    onEditorStateChange5 = editorState5 => {
        this.setState({
            editorState5,
        });
        this.props.data.RegistryContent = draftToHtml(convertToRaw(editorState5.getCurrentContent()));
    };

    onEditorStateChange6 = editorState6 => {
        this.setState({
            editorState6,
        });
        this.props.data.CampusContent2 = draftToHtml(convertToRaw(editorState6.getCurrentContent()));
    };

    render() {
        const { editorState } = this.state;
        const { editorState2 } = this.state;
        const { editorState3 } = this.state;
        const { editorState4 } = this.state;
        const { editorState5 } = this.state;
        const { editorState6 } = this.state;
        return (
            <form onSubmit={this.props.handleSubmit}>
                <div className="card-body" style={{ textAlign: 'left' }}>
                    <div className="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll text-left" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

                        <div className="row mb-5">
                            <div className="col-md-12">
                                <div className="col-md-12 fv-row mb-3">
                                    <label className="required fs-5 fw-bold mb2">Discover Image</label>
                                    <div className="image-input-wrapper  bgi-position-center" style={{ height: '400px', }}>
                                        <img src={this.props.DiscoverPictureElement !== "" ?
                                            this.props.DiscoverPictureElement :
                                            this.props.data.DiscoverPicture !== null ?
                                                this.props.data.DiscoverPicture.includes("simplefileupload") ?
                                                    this.props.data.DiscoverPicture :
                                                    `${serverLink}public/uploads/${shortCode}/content_uploads/${this.props.data.DiscoverPicture}` :
                                                ""} className="img-thumbnail" style={{ backgroundColor: '#eeeeee', height: '400px', width: '100%' }} alt="Discover Image" />
                                    </div>

                                </div>
                                <div className="col-md-12 mb-4 mb-10 form-group" >
                                    {/* <SimpleFileUpload
                                        apiKey={projectUploadAPI}
                                        onSuccess={(e, i) => this.props.onImageChange(e, "DiscoverPicture")}
                                        tag={`${projectName}-website-content`}
                                        accepted="image/*"
                                        maxFileSize={1}
                                        preview="true"
                                        width="100%"
                                    /> */}
                                    <small className="text-danger"><strong>File must not exceed 1MB</strong></small>
                                    <input
                                        className="form-control"
                                        type="file"
                                        name="DiscoverPicture"
                                        id="DiscoverPicture"
                                        onChange={this.props.onImageChange}
                                        accept="image/*"
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="col-md-12 fv-row  mb-3">
                                    <label className="required fs-5 fw-bold mb-2">Campus Image 1</label>
                                    <div className="image-input-wrapper  bgi-position-center" style={{ height: '400px', }}>
                                        <img
                                            src={this.props.campusHtmlElement !== "" ?
                                                this.props.campusHtmlElement :
                                                this.props.data.CampusImage !== null ?
                                                    this.props.data.CampusImage.includes("simplefileupload") ?
                                                        this.props.data.CampusImage : `${serverLink}public/uploads/${shortCode}/content_uploads/${this.props.data.CampusImage}` :
                                                    ""} className="img-thumbnail" style={{ backgroundColor: '#eeeeee', height: '400px', width: '100%' }} alt="Campus Image" />

                                    </div>

                                </div>
                                <div className="col-md-12 mb-4 form-group" >
                                    {/* <SimpleFileUpload
                                        apiKey={projectUploadAPI}
                                        onSuccess={(e, i) => this.props.onImageChange(e, "CampusImage")}
                                        tag={`${projectName}-website-content`}
                                        accepted="image/*"
                                        maxFileSize={1}
                                        preview="true"
                                        width="100%"
                                    /> */}
                                    <small className="text-danger"><strong>File must not exceed 1MB</strong></small>
                                    <input
                                        className="form-control"
                                        type="file"
                                        name="CampusImage"
                                        id="CampusImage"
                                        onChange={this.props.onImageChange}
                                        accept="image/*"
                                    />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="col-md-12 fv-row  mb-3">
                                    <label className="required fs-5 fw-bold mb-2">Campus Image 2</label>

                                    <div className="image-input-wrapper  bgi-position-center" style={{ height: '400px', }}>
                                        <img src={this.props.campusHtmlElement2 !== "" ?
                                            this.props.campusHtmlElement2
                                            : this.props.data.CampusImage2 !== null ? this.props.data.CampusImage2.includes("simplefileupload") ?
                                                this.props.data.CampusImage2 :
                                                `${serverLink}public/uploads/${shortCode}/content_uploads/${this.props.data.CampusImage2}` :
                                                ""}
                                            className="img-thumbnail" style={{ backgroundColor: '#eeeeee', height: '400px', width: '100%' }} alt="Campus Image2" />
                                    </div>

                                </div>
                                <div className="col-md-12 mb-4 form-group" >
                                    {/* <SimpleFileUpload
                                        apiKey={projectUploadAPI}
                                        onSuccess={(e, i) => this.props.onImageChange(e, "CampusImage2")}
                                        tag={`${projectName}-website-content`}
                                        accepted="image/*"
                                        maxFileSize={1}
                                        preview="true"
                                        width="100%"
                                    /> */}
                                    <small className="text-danger"><strong>File must not exceed 1MB</strong></small>
                                    <input
                                        className="form-control"
                                        type="file"
                                        name="CampusImage2"
                                        id="CampusImage2"
                                        onChange={this.props.onImageChange}
                                        accept="image/*"
                                    />
                                </div>
                            </div>

                        </div>

                        <div className="row mb-5">
                            <div className="col-md-12 fv-row">
                                <label className=" fs-5 fw-bold mb-2">Discover Content</label>
                                <Editor
                                    className="form-control form-control-solid"
                                    rows={4}
                                    name="DiscoverContent"
                                    id="DiscoverContent"
                                    onEditorStateChange={this.onEditorStateChange}
                                    wrapperClassName="form-control form-control-solid"
                                    editorClassName="form-control form-control-solid"
                                    editorState={editorState}
                                />
                            </div>
                            <div className="col-md-12 fv-row mt-7">
                                <label className=" fs-5 fw-bold mb-2">About Content</label>
                                <Editor
                                    className="form-control form-control-solid"
                                    rows={4}
                                    name="AboutContent"
                                    id="AboutContent"
                                    onEditorStateChange={this.onEditorStateChange2}
                                    wrapperClassName="form-control form-control-solid"
                                    editorClassName="form-control form-control-solid"
                                    editorState={editorState2}
                                />

                            </div>
                            <div className="col-md-12 fv-row mt-7">
                                <label className=" fs-5 fw-bold mb-2">Campus Content</label>
                                <Editor
                                    className="form-control form-control-solid"
                                    rows={4}
                                    name="CampusContent"
                                    id="CampusContent"
                                    onEditorStateChange={this.onEditorStateChange3}
                                    wrapperClassName="form-control form-control-solid"
                                    editorClassName="form-control form-control-solid"
                                    editorState={editorState3}
                                />
                            </div>

                            <div className="col-md-12 fv-row mt-7">
                                <label className=" fs-5 fw-bold mb-2">Campus Content2</label>
                                <Editor
                                    className="form-control form-control-solid"
                                    rows={4}
                                    name="CampusContent2"
                                    id="CampusContent2"
                                    onEditorStateChange={this.onEditorStateChange6}
                                    wrapperClassName="form-control form-control-solid"
                                    editorClassName="form-control form-control-solid"
                                    editorState={editorState6}
                                />
                            </div>
                            <div className="col-md-12 fv-row mt-7">
                                <label className=" fs-5 fw-bold mb-2">Education Content</label>
                                <Editor
                                    className="form-control form-control-solid"
                                    rows={4}
                                    name="EducationContent"
                                    id="EducationContent"
                                    onEditorStateChange={this.onEditorStateChange4}
                                    wrapperClassName="form-control form-control-solid"
                                    editorClassName="form-control form-control-solid"
                                    editorState={editorState4}
                                />
                            </div>
                            <div className="col-md-12 fv-row mt-7">
                                <label className=" fs-5 fw-bold mb-2">Registry Content</label>
                                <Editor
                                    className="form-control form-control-solid"
                                    rows={4}
                                    name="RegistryContent"
                                    id="RegistryContent"
                                    onEditorStateChange={this.onEditorStateChange5}
                                    wrapperClassName="form-control form-control-solid"
                                    editorClassName="form-control form-control-solid"
                                    editorState={editorState5}
                                />
                            </div>

                        </div>


                        <button type="submit" id="kt_modal_new_address_submit" data-kt-indicator={this.props.isLoading} className="btn btn-primary w-25 offset-sm-4 mb-9 mt-9">
                            <span className="indicator-label">Submit</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2" />
                            </span>
                        </button>
                    </div>
                </div>
            </form>
        );
    }
}

export default HomeContentForm;