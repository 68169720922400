import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import {
    generalDetailsReducer, homeContentDetailsReducer, loginDetailsReducer, permissionDetailsReducer
} from "./generaldetailsReducer";

import storage from "redux-persist/lib/storage";
import {courseDetailsReducer, facultyDataReducer, facultyDetailsReducer, facultyNavbarReducer} from "./facultyreducer";

const rootReducer = combineReducers({
    generalDetails: generalDetailsReducer,
    homeContentDetails: homeContentDetailsReducer,
    facultyDetails: facultyDetailsReducer,
    facultyData: facultyDataReducer,
    facultyNavbar: facultyNavbarReducer,
    courseDetails: courseDetailsReducer,
    permissionData: permissionDetailsReducer,
    loginDetails: loginDetailsReducer
});

const persistConfig = {
    key: "root",
    storage,
};

export default persistReducer(persistConfig, rootReducer);