import React, {Component} from "react";
import {Link} from "react-router-dom";
import {sidebarAccessControl} from "../../permission/permission";
import {connect} from "react-redux";
class FacultyNav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userPermission: this.props.loginDetails[0].IsAdmin,
        }
    }
    render() {
        return (
            <div className="header-menu align-items-stretch" data-kt-drawer="true"  data-kt-drawer-name="header-menu" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'200px', '300px': '250px'}" data-kt-drawer-direction="end" data-kt-drawer-toggle="#kt_header_menu_mobile_toggle" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}">

                <div className="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch" id="#kt_header_menu" data-kt-menu="true">
                    <div className="menu-item me-lg-1">
                        <a href={`/faculty/dashboard/${this.props.facultyData.Slug}`} className="menu-link py-3">
                            <span className="menu-title">Dashboard</span>
                        </a>
                    </div>
                    {
                        sidebarAccessControl([1, 2, 3, 4], this.state.userPermission) ?
                            <div className="menu-item me-lg-1">
                                {/*<Link to="/faculty/course" className="menu-link py-3">*/}
                                {/*    <span className="menu-title">Course</span>*/}
                                {/*</Link>*/}
                                <a href="/faculty/course" className="menu-link py-3">
                                    <span className="menu-title">Course</span>
                                </a>
                            </div>
                            : ""
                    }

                    {
                        sidebarAccessControl([1, 2, 4], this.state.userPermission) ?
                            <div data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start" className="menu-item menu-lg-down-accordion me-lg-1">
                        <span className="menu-link py-3">
                            <span className="menu-title">News</span>
                            <span className="menu-arrow d-lg-none"/>
                        </span>
                                <div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg py-lg-4 w-lg-225px">
                                    <div className="menu-item">
                                        <a href="/faculty/news/add" className="menu-link py-3">
                                    <span className="menu-bullet">
                                        <span
                                            className="bullet bullet-dot"/>
                                    </span>
                                            <span className="menu-title">Add News</span>
                                        </a>
                                    </div>
                                    <div className="menu-item">
                                        <a href="/faculty/news/list" className="menu-link py-3">
                                    <span className="menu-bullet">
                                        <span  className="bullet bullet-dot"/>
                                    </span>
                                            <span className="menu-title">News List</span>
                                        </a>
                                    </div>
                                </div>

                            </div>
                            : ""
                    }

                    {
                        sidebarAccessControl([1, 2, 4], this.state.userPermission) ?
                            <div data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start" className="menu-item menu-lg-down-accordion me-lg-1">
                        <span className="menu-link py-3">
                            <span className="menu-title">Event</span>
                            <span className="menu-arrow d-lg-none"></span>
                        </span>
                                <div  className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg py-lg-4 w-lg-225px">
                                    <div className="menu-item">
                                        <a href="/faculty/event/add" className="menu-link py-3">
                                    <span className="menu-bullet">
                                        <span
                                            className="bullet bullet-dot"></span>
                                    </span>
                                            <span className="menu-title">Add Event</span>
                                        </a>
                                    </div>
                                    <div className="menu-item">
                                        <a href="/faculty/event/list" className="menu-link py-3">
                                    <span className="menu-bullet">
                                        <span
                                            className="bullet bullet-dot"></span>
                                    </span>
                                            <span className="menu-title">Event List</span>
                                        </a>
                                    </div>
                                </div>

                            </div>
                            : ""
                    }

                    {
                        sidebarAccessControl([1, 2, 4], this.state.userPermission) ?
                            <div className="menu-item me-lg-1">
                                <Link to="/faculty/content" className="menu-link py-3">
                                    <span className="menu-title">Faculty Content</span>
                                </Link>
                            </div>
                            : ""
                    }

                </div>

            </div>

        )
    }
}


const mapStateToProps = (state) => {
    return {
        loginDetails: state.loginDetails,
        facultyData: state.facultyDetails,
    };
};

export default connect(mapStateToProps, null)(FacultyNav);