export function accessControl(pagePermission, userPermission) {
    if (!pagePermission.includes(parseInt(sessionStorage.getItem('userPermission')))){
        window.location.href = '/logout';
    }
}


export function sidebarAccessControl(pagePermission, userPermission) {
    if (!pagePermission.includes(userPermission)){
        return false;
    }else {
        return true;
    }
}