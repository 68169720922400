import {Component} from "react";

class Dateformat extends Component{
    dateFormat() {
        let date = new Date(this.props.date);
        let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
        let month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
        let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
        return `${day}-${month}-${year}`;
    }
    render() {
        return this.dateFormat();
    }
}

export default Dateformat;
