import React, {Component} from "react";
import { connect } from "react-redux";
import { Oval } from  'react-loader-spinner'
import {serverLink} from "../../resources/url";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import axios from "axios";
import Header from "../Header/header";
import swal from "sweetalert";
import {accessControl} from "../../permission/permission";

class GroupMembers extends Component {
    constructor(props) {
        super(props);
        accessControl([1]);
        this.state = {
            isLoading: 'on',
            checked: 0,
            columns: [
                {
                    name: "Full Name",
                    selector: row => `${row.staff_name}`,
                    sortable: true,
                },
                {
                    name: "Staff ID",
                    selector: row => `${row.StaffID}`,
                    sortable: true
                },
                {
                    name: "Designation",
                    selector: row => `${row.designation}`,
                    sortable: true
                },

                {
                    name: "Action",
                    cell: (row) => (
                        <input
                            key={row.StaffID}
                            value={row.StaffID}
                            type="checkbox"
                            id={row.StaffID}
                            name="checkUser"
                            data={row.StaffID}
                            onChange={this.onChecked}
                            checked={ row.IsAdmin !== 0  }
                        />

                    ),
                },

            ],
            data: []
        }
    }

    componentDidMount() {
        this.fetchStaff();
    }

    async fetchStaff() {
        await axios.get(`${serverLink}staff/permission/list/active`)
            .then(data => {
                const result = data.data.filter((e) => e.IsAdmin == this.props.permissionData.GroupID);
                this.setState({
                    data: [...result]
                });
            });
        this.setState({isLoading: false})
    }

    onChecked = (e) => {
        const formData = this.state.formData;
        let staff_id = e.target.value;
        let permission = this.props.permissionData.GroupID;

        if (!e.target.checked){
            const newData = this.state.data.map(obj =>
                obj.StaffID === staff_id ? { ...obj, IsAdmin: 3 } : obj
            );
            this.setState({  data: newData });
            this.onRemovePermission(e, 0, staff_id);

        }else {
            const newState = this.state.data.map(obj =>
                obj.StaffID === staff_id ? { ...obj, IsAdmin: 0 } : obj
            );
            this.setState({ data: newState});
            this.onAddPermission(e, permission, staff_id);
        }
    }


    onAddPermission = async (e, permission, staff_id) => {
        e.preventDefault();
        this.setState({
            isLoading: "on",
        });

        let sendData = {
            permission: permission,
            StaffID: staff_id
        };

        await axios.patch(`${serverLink}staff/permission/add`, sendData)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if (response === "success"){
                    this.showSuccessAlert("SUCCESS", "PERMISSION ADDED SUCCESSFULLY", "success")
                    return false;
                } else {
                    this.showAlert("Oops!", "Failed to add permission!", "error")
                    return false;
                }

            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                });
                console.error('ERROR', err);
            });
    };

    onRemovePermission = async (e, permission, staff_id) => {
        e.preventDefault();
        this.setState({
            isLoading: "on",
        });

        let sendData = {
            permission: permission,
            StaffID: staff_id
        };

        await axios.patch(`${serverLink}staff/permission/remove`, sendData)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if (response === "success"){
                    this.showSuccessAlert("SUCCESS", "PERMISSION REMOVED SUCCESSFULLY", "success")
                    return false;
                } else {
                    this.showAlert("Oops!", "Failed to remove permission!", "error")
                    return false;
                }

            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                });
                console.error('ERROR', err);
            });
    };

    showAlert(title, msg, type){
        return swal({
            title: title,
            text: msg,
            icon: type,
            button: "OK",
        });
    }

    showSuccessAlert(title, msg, type){
        return swal({
            title: title,
            text: msg,
            icon: type,
            button: "OK",
        }).then((value) => {
            this.fetchStaff();
        });
    }


    render() {
        const columns = this.state.columns;
        const data = this.state.data;
        const tableData = {
            columns,
            data,
            print: false,
            exportHeaders: true,
            filterPlaceholder: 'Search Staff',

        };
        return ( <>
                <Header isFaculty={false}/>
                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                    <div className="toolbar" id="kt_toolbar">
                        <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                            <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
                                <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">Permission
                                    <span className="h-20px border-dark  border-start ms-3 mx-2"/>
                                    GROUP
                                    <span className="h-20px border-dark  border-start ms-3 mx-2"/>
                                    MEMBERS
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className="post d-flex flex-column-fluid" id="kt_post">
                        <div id="kt_content_container" className="container-fluid">
                            {/* CONTENT START */}
                            {this.state.isLoading ?
                                <div style={{margin: 'auto', position: 'relative', justifyContent:'center', alignItems: 'center', display: 'flex' , height: '400px'}}>
                                    <Oval
                                        type="Oval"
                                        color="#eeeeee"
                                        height={50}
                                        width={50}
                                        secondaryColor="teal"
                                        timeout={15000} //3 secs
                                    />
                                </div>
                                :
                                <div className="card mb-5 mb-xl-10">
                                    <div className="card-header">
                                        <div className="card-title">
                                            <h3 className="text-uppercase"> {this.props.permissionData.GroupName} - MEMBERS</h3>
                                        </div>

                                    </div>

                                    <div className="card-body"  style={{textAlign: 'left'}}>
                                        <DataTableExtensions
                                            {...tableData}
                                        >
                                            <DataTable
                                                className="text-capitalize"
                                                noHeader
                                                defaultSortField="id"
                                                defaultSortAsc={false}
                                                pagination
                                                highlightOnHover/>
                                        </DataTableExtensions>

                                    </div>

                                </div>}

                            {/* CONTENT END */}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        permissionData: state.permissionData,
    };
};


export default connect(mapStateToProps, null)(GroupMembers);