import React, { Component } from "react";
import { convertToRaw, EditorState } from "draft-js";
import { stateFromHTML } from "draft-js-import-html";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
class HeadlineForm extends Component {
    constructor(props) {
        super(props);
        const contentBlocks = stateFromHTML(this.props.data.messageMsg);
        this.state = {
            editorState: EditorState.createWithContent(contentBlocks !== "" ? contentBlocks : ''),

        };


    }

    onEditorStateChange = editorState => {
        this.setState({
            editorState,
        });
        this.props.data.messageMsg = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    };




    render() {
        const data1 = [
            { name: 'one', color: '#8d4e85', colorName: 'Berry Black', slug: 'berry-black' },
            { name: 'two', color: '#ff8c00', colorName: 'Dark Orange', slug: 'dark-orange' },
            { name: 'three', color: '#281C02', colorName: 'Gold Black', slug: 'gold-black' },
            { name: 'four', color: '#aa6c39', colorName: 'Gold Dark', slug: 'gold-dark' }
        ];

        const { editorState } = this.state;
        return (
            <form onSubmit={this.props.handleSubmit}>
                <div className="card-body" style={{ textAlign: 'left' }}>
                    <div className="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll text-left" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">
                        <div className="row mb-5">
                            <div className="col-md-6 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Link Title</label>
                                <input type="text" className="form-control form-control-solid  mb-9" placeholder="Link Title"
                                    name="linkTitle"
                                    id="linkTitle"
                                    onChange={this.props.onEdit}
                                    value={this.props.data.linkTitle}
                                />
                            </div>
                            <div className="col-md-6 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Link Path</label>
                                <input type="text" className="form-control form-control-solid  mb-9" placeholder="Link Path"
                                    name="linkPath"
                                    id="linkPath"
                                    onChange={this.props.onEdit}
                                    value={this.props.data.linkPath}
                                />
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-6 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Background Color</label>
                                <select className="form-control form-control-solid"
                                    name="bgColour"
                                    id="bgColour"
                                    onChange={this.props.onEdit}
                                    value={this.props.data.bgColour}
                                >
                                    {
                                        data1.map((item, index) =>
                                            <option key={index} value={item.slug} style={{ backgroundColor: item.color, color: "#ffffff" }}>{item.colorName}</option>
                                        )
                                    }
                                </select>
                            </div>
                            <div className="col-md-6 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Description</label>
                                <Editor
                                    className="form-control form-control-solid"
                                    rows={4}
                                    name="messageMsg"
                                    id="messageMsg"
                                    onEditorStateChange={this.onEditorStateChange}
                                    wrapperClassName="form-control form-control-solid"
                                    editorClassName="form-control form-control-solid"
                                    editorState={editorState}
                                />
                            </div>
                        </div>
                        <button type="submit" id="kt_modal_new_address_submit" data-kt-indicator={this.props.isLoading} className="btn btn-primary w-25 offset-sm-4 mb-9 mt-9">
                            <span className="indicator-label">Submit</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2" />
                            </span>
                        </button>
                    </div>
                </div>
            </form>
        );
    }
}

export default HeadlineForm;